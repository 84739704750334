//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;600;700&display=swap');
$font-family-base: 'Noto Sans JP', sans-serif;

@import "variables";
@import "bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------
/*
.wpforms-field-container
    wpforms-field wpforms-field-text
        label
        input
*/
.wpforms-field-container{

    .wpforms-image-choices{
        @extend .px-0;
        .wpforms-image-choices-item{
            @extend .col-4;
            @extend .px-0;
            @extend .py-3;
            img{
                @extend .w-100;
            }
            .wpforms-image-choices-label{
                @extend .m-0;
            }
        }
    }
    .wpforms-field{
        input{
            @extend .form-control;
            @extend .form-control-lg;
            &::placeholder{
                @extend .text-gray-400;
            }
        }
        .wpforms-field-description{
            @extend .small;
        }
    }
}

.post-password-form
{
    text-align: center;
}
//---------------------------------------------
// 3.Components
//---------------------------------------------

// @import "components/slider";
@import "components/wordpress";
@import "components/icomoon";
@import "components/hamburgers";
